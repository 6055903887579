import React, { useState, useEffect } from 'react';
import { instance as api, setAuthHeader } from '../api';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
    Grid,
    Table,
    TableHeaderRow,
    ColumnChooser,
    TableColumnVisibility,
    Toolbar,
}
    from '@devexpress/dx-react-grid-material-ui';
import { Box, Paper } from '@material-ui/core';
import { Result, UserRows } from '../Models/userRows';
import { AuthComponentProps } from "../AuthProvider";
import { TableSkeleton } from "../Utilities/Skeletons";
import { HeaderCellComponent } from "../Utilities/TableUtilities";
import { msalRequest } from '../config';

interface Props extends RouteComponentProps<{ query: string }> {
    auth: AuthComponentProps;
}

const cellComponent = (props: any) => {
    const { column } = props;
    if (column.name === 'field') {
        return <Table.Cell {...props} style={{
            backgroundColor: "#abb3e2"
        }} />
    }
    return <Table.Cell {...props} />;
};
const UserInfo: React.FC<Props> = (props: Props) => {
    const [rows, setRows] = useState<UserRows[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const columns = [
        { name: 'field', title: 'Field' },
        { name: 'adProp', title: 'AD Fieldname' },
        {
            name: 'adValue', title: 'Active Directory (AD)',
            getCellValue: (row: any) => ((row.adValue !== row.msGraphValue || row.adValue !== row.adsValue)
                && row.adsValue !== '-' && row.msGraphValue !== '-' && row.adValue !== '-') ?
                <span style={{ color: 'red' }}>{row.adValue}</span> : row.adValue
        },
        { name: 'msGraphProp', title: 'Microsoft Graph Fieldname' },
        {
            name: 'msGraphValue', title: 'Microsoft Graph',
            getCellValue: (row: any) => ((row.adValue !== row.msGraphValue || row.msGraphValue !== row.adsValue)
                && row.adsValue !== '-' && row.msGraphValue !== '-' && row.adValue !== '-') ?
                <span style={{ color: 'red' }}>{row.msGraphValue}</span> : row.msGraphValue
        },
        { name: 'adsProp', title: 'Arup Data FieldName' },
        {
            name: 'adsValue', title: 'Arup Data Services (ADS)',
            getCellValue: (row: any) => ((row.adValue !== row.adsValue || row.msGraphValue !== row.adsValue)
                && row.adsValue !== '-' && row.msGraphValue !== '-' && row.adValue !== '-') ?
                <span style={{ color: 'red' }}>{row.adsValue}</span> : row.adsValue
        },
    ];
    const defaultHiddenColumnNames: string[] = ['adProp', 'msGraphProp', 'adsProp'];
    const query = props.match.params.query + props.location.hash;
    useEffect(() => {
        let isMounted = true;
        let reg = /^\d+$/;
        const getStaff = async (endpoint: string) => {
            setIsLoading(true);
            const token = await props.auth.getAccessToken(msalRequest.scopes);
            setAuthHeader(token);
            await api.get<Result>(`${endpoint}`)
                .then((resp) => {
                    if (isMounted) {
                        setRows(resp.data.result);
                        setIsLoading(false);
                    }
                }).catch(err => console.log(err));
        }

        if (reg.test(query)) {
            getStaff(`GetUserByStaffId/${query}`);
        } else {
            getStaff(`GetUserByUpn/${encodeURIComponent(query)}`);
        }

        return () => {
            isMounted = false;
        };
    }, [query, props]);
    if (props.auth.isAuthenticated) {
        return (
            <Paper>
                {isLoading ? (
                    <Box pt={1}>
                        <TableSkeleton />
                    </Box>
                ) : (
                        <>
                            <Grid
                                rows={rows}
                                columns={columns}>
                                <Table cellComponent={cellComponent} />
                                <TableHeaderRow cellComponent={HeaderCellComponent} />
                                <TableColumnVisibility
                                    defaultHiddenColumnNames={defaultHiddenColumnNames}
                                />
                                <Toolbar />
                                <ColumnChooser />
                            </Grid>
                        </>)
                }
            </Paper>
        );
    }
    else {
        return (<div> not authorised </div>);
    }
}

export default withRouter(UserInfo)